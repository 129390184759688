import React, { useState } from 'react'
import { AtSymbolIcon, PencilIcon, PhoneIcon, UserIcon, } from '@heroicons/react/solid'
import ContactInput from './ContactInput';
import 'animate.css'
import { db } from '../firebase';
import firebase from 'firebase';
import swal from 'sweetalert';


function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");
    const [subject, setSubject] = useState("");
    const [phone, setPhone] = useState("");

    const sendMessage = (e) => {
     
        e.preventDefault();
        
        db.collection("Message").add({
            Name:name,
            Email:email,
            message:msg,
            Subject:subject,
            Phone:phone,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setName("");
        setEmail("");
        setMsg("");
        setPhone("");
        setSubject("");
     
        swal("Your message has been send", "Thanks for contacting me,I will be reply back within 24hr", "success");
      
    }

    return (
        <div className="flex flex-col md:flex-row  justify-center items-center h-full m-10 sm:m-14 md:m-20 bg-white md:my-1">

            <img src={require('../Assests/Emails.gif').default} className="mr-10 w-6/12 sm:w-9/12 md:w-4/6" alt="" />

            <div>
                <p className=" animate__animated animate__lightSpeedInRight text-blue-400 text-2xl sm:text-4xl md:text-2xl lg:text-4xl font-serif font-extrabold p-2 transform ">𝕮𝖔𝖓𝖙𝖆𝖈𝖙 𝕸𝖊 <div className=" inline-flex transition animate-pulse delay-100 duration-1000 text-red-500 transform sm:text-3xl md:text-2xl lg:text-4xl ">( ͡ᵔ ͜ʖ ͡ᵔ )</div></p>
                <form className="animate__animated animate__lightSpeedInLeft flex flex-col max-w-xs md:max-w-md md:w-4/6 lg:w-full">
                    
                    <ContactInput type="text" Placeholder="Name" Icon={UserIcon} color="text-red-500"
                        value={name}
                        onChange={(e) => setName(e.target.value)} 
                        />
                    
                    <ContactInput type="email" Placeholder="Email" Icon={AtSymbolIcon} color="text-green-500"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} 
                        />

                    <ContactInput type="text" Placeholder="Subject" Icon={PencilIcon} color="text-blue-500"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)} 
                        />
                    
                    <ContactInput type="tel" Placeholder="Phone/Mobile no" Icon={PhoneIcon} color="text-blue-500"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)} 
                        />

                    
                    <textarea placeholder="Enter your message here" className="textArea"  
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)} 
                        />

                    <button onClick={sendMessage}
                        className="bg-blue-400 text-white text-xl p-2 sm:p-4 font-medium rounded-md ring-2 ring-blue-400 ring-offset-4 ring-offset-blue-100 hover:bg-white hover:text-blue-400 ">Send Message</button>
                </form>
            </div>
        </div>
    )
}

export default Contact
