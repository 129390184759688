import './App.css';
import {
  Switch,
  Route,
} from "react-router-dom";
import Home from './Components/Home';
import Header from './Components/Header';
import About from './Components/About';
import Projects from './Components/Projects';
import Skill from './Components/Skill';
import Contact from './Components/Contact';

function App() {
  return (
    <div className="App">
     <Header />
     <Switch>
     
        <Route path="/" component={Home}  exact />
          
        
        <Route path="/about" component={About} />
        {/* <Route  path="/service" component={Service} /> */}
        <Route  path="/project" component={Projects} />
        <Route path="/skill" component={Skill} />
        <Route  path="/contact" component={Contact}/>

      </Switch>
    </div>
  );
}

export default App;
