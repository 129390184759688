import React from 'react'
import { Link } from "react-router-dom";
function HeaderIcon({ Icon ,title ,src}) {
    return (
        <div className="icon focus-within:shadow-inner ">
            <Icon  className="text-blue-300  hover:text-green-200 cursor-auto sm:hidden md:block "/>
            <Link to={src} className=" outline-none text-transparent " >{title}</Link>
        </div>
    )
}

export default HeaderIcon
