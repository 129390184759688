import React from 'react'
import ProjectsCard from './ProjectsCard'
import 'animate.css'

function Projects() {
    return (
        <div className=" p-0 sm:px-20 md:px-32 bg-gray-50 pb-10">
            <p className="text-3xl md:text-5xl shadow-xl mb-10 text-green-200 bg-white p-5  rounded-b-2xl font-serif border-b-4 border-blue-300 animate__animated animate__fadeInDown animate__slow"><span className="filter skew-y-6">Projects</span></p>
            {/* <ProjectsCard
                title="FaceBook Clone"
                link="https://faisaldev.netlify.app/"
                description={[
                    "In Facebook Clone i used a Facebook Devloper Api to facilate user to login using their Facebook Account.",
                    "User can Upload a photo with their post.",
                    "User image is stored using firebase Storage.",
                    "It uses a nextAuth for session Firebase to store a user data or Posts."
                    ]}
                    ProjectPic="fbclone.JPG"
                    next
                    tailwind
                    InPro
            /> */}
            <ProjectsCard
                title="Slack Clone"
                link="https://slack-mfa.web.app/"
                ProjectPic="slackCline.JPG"
                material
                redux
                styled
                description={[
                    "Slack Clone is a Xerox of original SLACK App.In this ClonE_App you can 𝐚𝐝𝐝 your own channel.",
                    "You can view messages of any channel. I used a firebase Google email authentication to validate user.",
                    " User can signup/signIn by using their gmail account only. Click on Your profile pic for SignOut."]}
            />
            <ProjectsCard
                title="LinkedIn Clone"
                link="https://linkedin-clone-fa.web.app/"
                ProjectPic="2 linkedin-clone-fa.web.app.JPG"
                redux
                material
                description={[
                    "In linkedIn Clone user can login by using their email.",
                    "In this project i used a firebase email/password authentication"
                ]}
            /> 
            <ProjectsCard
                title="My Protfolio"
                link="https://faisal-dbc82.web.app/"
                ProjectPic="protfolio.JPG"
                tailwind
                description={[
                    "My protfolio is the Awesome Website that use a React js tailwindCss ,It is a super responsive to all devices."]}
            />


        </div>
    )
}

export default Projects
