import React from 'react'
import ProgressBar from './ProgressBar'
import SkillCircle from './SkillCircle'
import 'animate.css'
import CardCertificate from './CardCertificate'

function Skill() {
    return (
        <div className="flex flex-col gap-12 bg-white">
            <div className="flex flex-col-reverse sm:flex-row-reverse py-4 mt-3 sm:mt-0 sm:py-4 px-0 sm:px-10 items-center sm:items-stretch  mb-5 gap-3 md:gap-5">
                <div className=" w-4/5 sm:w-2/4 animate__slideInRight animate__animated">
                    <ProgressBar per="90%" title="Html" color="red" />
                    <ProgressBar per="70%" title="CSS" color="green" />
                    <ProgressBar per="80%" title="React JS" color="blue"/>
                    <ProgressBar per="85%" title="Tailwind CSs" color="purple"/>
                </div>
                <div className=" w-4/5 sm:w-2/4">
                    <span className=" text-2xl sm:text-3xl font-semibold text-red-500 shadow-lg rounded-b-2xl rounded-t-md sm:rounded-2xl pb-1 pt-12 sm:pt-20 px-4 hover:text-green-500 animate__animated animate__flipInX animate__slow	2s " style={{fontFamily:'cursive'}}>My Skills</span>
                    <p className="mt-4 text-base sm:text-lg font-normal font-sans text-gray-800 animate__slideInLeft animate__animated"> 
                        I create successful responsive websites that are fast, easy to use, and built with best practices. The main area of my expertise is front-end development,<span className="text-red-400"> HTML</span>, <span className="text-green-500">Css</span>, <span className="text-blue-500">ReactJs</span> & <span className="text-purple-500">TailwindCss</span> building small and medium web apps,  features, animations, and coding interactive layouts.
                        </p>
                </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-5 gap-12 items-center animate__animated animate__fadeInUp mb-10">
                <p className="  w-full col-span-2 sm:col-span-4 font-bold text-2xl sm:text-3xl md:text-4xl pl-10 border-l-8 border-t border-b  border-green-300 shadow-md text-red-400 py-5">Tech I'm familiar with 👨‍💻</p>
                
                <SkillCircle title="Html" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain-wordmark.svg" />
                <SkillCircle title="Css" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain-wordmark.svg" />
                <SkillCircle title="ReactJs" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
                <SkillCircle title="JavaScript" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
                <SkillCircle title="TailwindCSS" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-original-wordmark.svg" />
                <SkillCircle title="Firebase" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain-wordmark.svg" />
                <SkillCircle title="Redux" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg" />
                <SkillCircle title="StyeldComponent" src="sc edit.jpg" img />
                <SkillCircle title="Node.js" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg"/>
                <SkillCircle title="Mysql" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg" />
                <SkillCircle title="Next.js" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original-wordmark.svg"/>

                <SkillCircle title="MongoDb" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg" />

                <SkillCircle title="MaterialUI" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg" />
                <SkillCircle title="Github" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" />
                <SkillCircle title="git" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg" />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-5 gap-12 items-center animate__animated animate__fadeInUp mb-10">
                <p className="  w-full col-span-2 sm:col-span-4 font-bold text-2xl sm:text-3xl md:text-4xl pl-10 border-l-8 border-t border-b  border-red-300 shadow-md text-red-400 py-5">Tools 🛠️</p>
                
                <SkillCircle title="Chrome" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/chrome/chrome-original.svg" />
                <SkillCircle title="VsCode" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-plain.svg" />
                <SkillCircle title="ReduxDevTools" src="reduxDevtools.jpg" img/>
                <SkillCircle title="Atom" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/atom/atom-original.svg" />
                



            </div>
             <div>
        <p className="animate__animated animate__fadeInLeft shadow py-2 pl-8 border-b-2 border-red-300 font-bold text-4xl text-pink-600 mt-10 " style={{fontFamily:"cursive"}}>SoloLearn Certificates </p>
        <div className=" grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-y-14 mt-4">
          <CardCertificate verifyCer="Html " src="HTML_certificate" cerNo="1014-3013378"/>
          <CardCertificate verifyCer="JavaScript " src="JavaScript_certificate" cerNo="1024-3013378"/>
          <CardCertificate verifyCer="React Js & Redux" src="react_certificate" cerNo="1097-3013378"/>
          <CardCertificate verifyCer="Css " src="CSS_certificate" cerNo="1023-3013378"/>

          <CardCertificate verifyCer="Responsive-Web-Design" src="Responsive-Web-Design_certificate" cerNo="1162-3013378"/>
          
        </div>
      </div>

        </div>
    )
}

export default Skill
