import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDTLz4avC7rFYRYPTCGhDPDR0z4yECjP9s",
    authDomain: "faisal-dbc82.firebaseapp.com",
    projectId: "faisal-dbc82",
    storageBucket: "faisal-dbc82.appspot.com",
    messagingSenderId: "979293257707",
    appId: "1:979293257707:web:e80c413548fc51cd4f63ae"
  };


const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();


export {db}
