import React from 'react'

function ContactInput({ Placeholder, type, Icon, color ,value,onChange}) {
    return (
        <div className="relative flex w-full ">
            <input 
            value={value} 
            onChange={ onChange}
            type={type} required placeholder={Placeholder} className={" flex-grow p-2 sm:p-3 relative w-60 sm:w-80 mb-4 rounded bg-gray-0 focus:bg-white placeholder-gray-500 placeholder-opacity-90 focus:placeholder-gray-300  outline-none text-left focus-within:ring-opacity-0 focus:shadow-md ring-2  ring-blue-200 ring-offset-1 ring-offset-green-100 " + color} />
            <span className="z-10  absolute w-8 right-0 pr-3 py-3 items-center">
                <Icon className={color} />
            </span>
        </div>
    )
}

export default ContactInput
