import React from 'react'
// import CardCertificate from './CardCertificate';


function About() {
    return (
        <div className="flex flex-col-reverse md:grid md:grid-cols-5 ">
            <div className="m-5 md:m-2 md:col-span-3 ">
                <p className="hidden md:grid md:text-4xl lg:mb-5 text-blue-400" style={{ fontFamily: "" }}>𝕸𝓔  &nbsp; 𝕸🏋💲𝓔👢ቻ  &nbsp;&  🕴 </p>
                <p className="  md:hidden text-xl sm:text-3xl text-green-400 " style={{ fontFamily: "cursive" }}> Me Myself & I</p>
                <p className=" text-base sm:text-xl">
                    I'm a Front-End Devloper located in New-delhi,<img className="inline-flex" src={require("../Assests/india.png").default} width={20} alt="Love for India" />.I have a serious passion for UI effects, animations and creating intuitive, dynamic user experiences.I have always been passionate about everything related to technology and IT. I also love to code things from scratch, and enjoy bringing ideas to life in the browser.</p>

                <p className=" text-base sm:text-xl">
                    I love to travel new places.
                    Playing games.
                    Also interested in 3d anmiation using blender and Game Devlopment <span className="devicon-unity-original colored"></span> <span className="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/unity/unity-original-wordmark.svg"></span>
                   <br/> I always facinate about new technology.
                   <br/>
                </p>
                {/* <p className=" text-base sm:text-xl mt-10">
                    My B.E Final Year Project is Face Mask Recognition System using A.I .
                </p> */}
                <a href={require('../Assests/faisal.pdf').default} download>
                    <button className="mt-5 md:ml-2 lg:mt-10  outline-none bg-blue-400 text-white p-4 rounded-full ring-2 ring-blue-500  ring-offset-4">Download My Resume</button>
                </a> 
            </div>
            <div className=" mx-14 my-2 md:m-0 md:col-span-2">
                <img src={require('../Assests/Programming.gif').default} alt="lo"  />
            </div>
              
     
        </div>
    )
}

export default About
