import React from 'react'

function CardCertificate({ verifyCer , src ,cerNo}) {
    return (
        <div className="wrapper flex m-auto">
            <div class=" min-h-0  flex  justify-center items-center">
                <div class="w-80 rounded-md cursor-pointer shadow-lg overflow-hidden hover:shadow-xl transform hover:scale-105 duration-500">
                    <img src={require(`../Assests/${src}.jpg`).default} alt={verifyCer} />
                    <div class="p-4 bg-white">
                        <a href={`https://www.sololearn.com/Certificate/${cerNo}/pdf/`} class="text-sm font-semibold text-red-50 bg-red-400 py-1 px-3 rounded-full">Verfiy</a>
                        <h1 class="mt-4 font-bold text-2xl">{verifyCer}</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardCertificate
