import React from 'react'
import Style from './Style';
import 'animate.css'
import { Link } from 'react-router-dom'

import SkillCircle from './SkillCircle';
import AboutHome from './AboutHome';
import './Style.css'

function Home() {

  return (
    <div className="flex flex-col">

      <div className="hidden bodo border-gray-100 border-l-4 border-opacity-40 border-r-4 md:flex flex-col animate__animated animate__fadeInDown animate__slower hover:shadow-lg hover:opacity-60 mb-5 m-auto pt-16 pb-4 px-1 shadow-md rounded-b-xl">
        <Style />
      </div>

      <div className=" flex flex-col sm:flex-none items-center place-content-center justify-center" >
        <span
          style={{ filter: 'drop-shadow(16px 16px 20px orange) invert(75%) ', fontFamily: 'cursive' }}
          className="flex p-auto m-auto md:hidden text-3xl sm:text-4xl font-bold text-yellow-300 italic  ">Ϝꍏ♗ⓢꍏ↳ ꍏ☡♄ꍏ☈
        </span>
        <Link to="/contact" className="flex animate__animated animate__zoomInDown animate__delay animate__slower text-blue-400 mt-5 sm:mt-0 items-center justify-center">
          <button className="text-xs sm:text-sm md:text-base font-medium md:font-bold items-center justify-center py-2 md:py-5 px-3 md:px-10 mt-3 sm:mt-5  hover:bg-blue-500 hover:text-white  rounded-full ml-10 border-2 md:border-4 hover:border-blue-500 border-blue-300 shadow-xl">Let's Build Something Special</button>
        </Link>
      </div>
      <div className="flex mt-5 animate__animated animate__rubberBand animate__backInRight  border-l-8 border-yellow-300 shadow-md"><AboutHome /></div>

      <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-5 gap-12 items-center animate__animated animate__rubberBand  animate__backInLeft mt-5  ">
        <p className=" w-full col-span-2 sm:col-span-4 font-bold text-2xl md:text-4xl pl-10 border-l-8 border-green-300 shadow-md text-red-400 py-5">Skills 💼</p>
        <SkillCircle title="HTML" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain-wordmark.svg" />
        <SkillCircle title="CSS" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain-wordmark.svg" />
        <SkillCircle title="React Js" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" />
        <SkillCircle title="JavaScript" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" />
        <SkillCircle title="TailwindCSS" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-original-wordmark.svg" />
        <SkillCircle title="Firebase" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain-wordmark.svg" />
        <SkillCircle title="Redux" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg" />
        <SkillCircle title="StyeldComponent" src="sc edit.jpg" img />
        <SkillCircle title="Node.js" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg" />
        <SkillCircle title="mysql" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original-wordmark.svg" />
        <SkillCircle title="Next.js" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original-wordmark.svg" />

        <SkillCircle title="mongoDb" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg" />

        <SkillCircle title="Material UI" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg" />
        <SkillCircle title="Github" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg" />
        <SkillCircle title="git" src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg" />

      </div>
    


    </div>
  )
}

export default Home
