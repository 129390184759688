import React from 'react'
import './Style.css'

function Style() {
    return (
<div className="bod  ml-5 pl-10">
  <div className="wrapper ">
    <div className=" static-txt"><span className=" animate__animated animate__flash animate__delay-2s  animate__slower animate__infinite">🕴</span> am</div>
        <ul className=" dynamic-txt  ">
        <li ><span>𝓜𝓭 𝓕𝓪𝓲𝓼𝓪𝓵 𝓐𝔃𝓱𝓪𝓻</span></li>
        <li ><span>𝓦𝓮𝓫 𝓓𝓮𝓿𝓵𝓸𝓹𝓮𝓻</span></li>
        <li ><span>𝓔𝓷𝓰𝓲𝓷𝓮𝓮𝓻</span></li>
        <li ><span>𝓤𝓘 𝓓𝓮𝓼𝓲𝓰𝓷𝓮𝓻</span></li>
        {/* <li ><span>𝓕𝓾𝓽𝓾𝓻𝓮 𝓔𝓷𝓽𝓻𝓮𝓹𝓻𝓮𝓷𝓮𝓾𝓻</span></li> */}
        </ul>
    </div>
    </div>

    )
}

export default Style
