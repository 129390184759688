import React from 'react'

function ProjectsCard({ title, description, link,ProjectPic ,next,redux,material,styled,devicon,tailwind,InPro}) {
  return (
    <div className="flex flex-col bg-white items-center md:flex-row  min-w-full min-h-full shadow-xl p-2 rounded-xl mb-14 sm:mb-20 border-2 border-blue-200 ">
        <h1 className="font-bold text-2xl sm:text-3xl text-red-300 p-1 flex md:hidden md:order-none order-1" style={{ fontFamily: "cursive" }}>{title}{InPro && <span className="text-xs text-red-600 bg-black rounded-3xl p-2 ml-1"><h1>In ProDuctioN</h1></span>}</h1>
      <div className="order-3 md:order-none p-1">
        <h1 className="hidden md:flex font-bold text-3xl text-red-300" style={{ fontFamily: "cursive" }}>{title}{InPro && <span className="text-xs text-red-600 bg-black rounded-3xl p-2 ml-1">In ProDuctioN</span>}</h1>
        <a className=" inline-flex md:inline-block text-xs text-blue-500 underline ml-1 mt-10 md:mt-0" href={link}>{link}</a>
        {/* <a href="" className=" inline-flex devicon-github-original colored  ml-4 p-1 shadow-2xl bg-gray-50 rounded-3xl text-xl"></a> */}
        <p className="text-gray-600 pt-2 max-w-screen-sm text-left"> {description}
        </p>
        <p className="font-mono font-semibold underline text-green-600 mt-5 ml-5 ">Technology Used : </p>
        <ul className="grid grid-cols-2 gap-4  mx-10 mt-3" >
          <li className="devicon-react-original colored hover:scale-105 hover:bg-blue-50 rounded-full p-2 w-40 transform"> React Js</li>
         {redux && <li className="devicon-redux-original colored hover:scale-105 hover:bg-purple-50 rounded-full p-2 w-40 transform"> Redux</li>}
          <li className="devicon-firebase-plain colored hover:scale-105 hover:bg-yellow-50 rounded-full p-2 w-40 transform"> Firebase</li>
         {material && <li className="devicon-materialui-plain colored hover:scale-105 hover:bg-indigo-50 rounded-full p-2 w-40 transform"> Material UI</li>}
         {styled && <li className=" text-purple-500 flex font-serif text-sm hover:scale-105 hover:bg-gray-50 rounded-full p-2 w-40 transform" ><img src={require('../Assests/sc.png').default} className=" h-4 pr-1" alt="" />Styled component</li>}
          {next && <li className="devicon-nextjs-plain colored hover:scale-105 hover:bg-gray-50 rounded-full p-2 w-40 transform"> Next Js</li>}
         {devicon && <li className="devicon-devicon-plain colored hover:scale-105 hover:bg-yellow-50 rounded-full p-2 w-40 transform"> DevIcon</li>}
          <li className="devicon-html5-plain colored hover:scale-105 hover:bg-red-50 rounded-full p-2 w-40 transform"> Html</li>
          <li className="devicon-css3-plain colored hover:scale-105 hover:bg-blue-50 rounded-full p-2 w-40 transform"> Css</li>
          {tailwind && <li className="devicon-tailwindcss-plain colored hover:scale-105 hover:bg-blue-50 rounded-full p-2 w-40 transform"> TailwindCss</li>}
          
        </ul>
      </div>
      <div className="flex order-2 md:order-none">
        <a href={link}><img className=" shadow-xl animate-pulse p-2 bg-transparent my-5 sm:my-0 rounded-3xl"
         src={(require('../Assests/' + ProjectPic).default)} alt="" /></a>
      </div>
    </div>
  )
}

export default ProjectsCard
