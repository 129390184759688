import 'animate.css'
import React, { useState } from 'react'
import { AcademicCapIcon, BriefcaseIcon, HomeIcon } from '@heroicons/react/solid'
import { MailIcon, DesktopComputerIcon } from '@heroicons/react/outline'
import HeaderIcon from './HeaderIcon';
import { Link } from "react-router-dom";
import './HeaderCSS.css'
import MobileMenu from './MobileMenu';


function Header() {
    const [IsOpen ,setIsOpen] = useState(false);
    return (
        <div className="Header pl-5 sm:pl-0 flex justify-between sm:justify-around py-2 shadow-lg items-center sticky z-50 top-0 min-w-full sm:min-w-full  ">
            {/* Left side of Header */}
            <Link to="/" className=" text-transparent" >
                <div className="flex flex-col items-center sm:items-start">
                    <h1 className="logo " style={{ fontFamily: " backdrop-brightness-125" }}>Ϝꍏ♗ⓢꍏ↳ ꍏ☡♄ꍏ☈ </h1>
                    <p className=" text-xs sm:text-sm  font-medium text-purple-600 underline  italic " style={{ fontFamily: "cursive" }}>☂♄€ ω€♭ ◗€✓↳⊙ρ€☈</p>
                </div></Link>
            {/* Right side of header */}
            <div className="hidden sm:flex sm:space-x-2 md:space-x-4 lg:space-x-6">

                <HeaderIcon src="/" title="𝓗𝓸𝓶𝓮" Icon={HomeIcon} />

                <HeaderIcon src="/about" title="𝓐𝓫𝓸𝓾𝓽" Icon={AcademicCapIcon} />

                <HeaderIcon src="/skill" title="𝓢𝓴𝓲𝓵𝓵𝓼" Icon={BriefcaseIcon} />

                <HeaderIcon src="/project" title="𝓦𝓸𝓻𝓴" Icon={DesktopComputerIcon} />

                <HeaderIcon src="/contact" title="𝓒𝓸𝓷𝓽𝓪𝓬𝓽" Icon={MailIcon} />  
            </div>
            <div className="flex sm:hidden px-5 cursor-pointer " onClick={()=>setIsOpen(!IsOpen)}>
                { !IsOpen ? (<img src={require("../Assests/menu.png").default} height={40} width={40} alt="menu" className="animate__animated animate__rotateIn"/>
                ) :(<>
                <img src={require("../Assests/menu (1).png").default} height={40} width={40} alt="menu"  className="animate__animated animate__rotateOut"/>
                <MobileMenu /></>
                )}
            </div>
        </div>
        
    )
}

export default Header
