import React from 'react'

function SkillCircle({title ,src ,img}) {
    return (
        <div className="group flex flex-col items-center text-center justify-start w-20 h-20 p-4 sm:w-28 sm:h-28 rounded-full shadow-md m-auto  border-b-2 border-opacity-30 group-hover:border-opacity-10 border-gray-700"  > 
           <>{img &&  <img  className=" relative transform hover:scale-150 transition-all duration-700 ease-in-out " src={(require('../Assests/' + src).default)} alt="img"/>}
            {!img && <img  className=" relative transform hover:scale-150 transition-all duration-700 ease-in-out " src={src} alt="img"/>}
</>
            <p className="mt-5 p-1 text-opacity-90 group-hover:text-opacity-20 text-xs sm:text-sm text-gray-500 font-semibold" style={{fontFamily:'cursive'}}>{title}</p>
        </div>
    )
}

export default SkillCircle