import React from 'react'

function ProgressBar({title , per ,color}) {
  return (
    <div className="mt-5">
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className={`shadow text-xs font-semibold inline-block py-1.5 px-3 uppercase rounded-full text-${color}-400 bg-gray-200`}>
              {title}
            </span>
          </div>
          <div className="text-right">
            <span className={`text-xs font-semibold inline-block text-${color}-400`}>
              {per}
            </span>
          </div>
        </div>
        <div className={`overflow-hidden h-2 mb-4 text-xs flex shadow rounded  `}>
          <div style={{ width:`${per}`}} className={`shadow-md flex flex-col text-center whitespace-nowrap text-white justify-center brightness-200 bg-${color}-400 animate__animated animate__fadeInLeft animate__delay-1s animate__slower`} ></div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
