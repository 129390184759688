import React from 'react'
import 'animate.css'

function AboutHome() {
    return (
        <div className="grid grid-cols-4 md:grid-cols-10  flex-col md:rounded-br-lg pb-4 md:p-0">
            <p className="col-span-4 md:col-span-10 text-2xl md:text-4xl font-bold text-indigo-400  pl-10">Who I am </p>
            <p className=" col-span-4 md:col-span-9 font-medium text-lg md:text-xl text-gray-700 my-6 pl-10 pr-5">
            I'm a Front-End Developer located in New-delhi,India.I have a serious passion for UI effects, animations and creating intuitive, dynamic user experiences.I have always been passionate about everything related to technology and IT. 
            I also love to code things from scratch, and enjoy bringing ideas to life in the browser.
            </p>
            <img src={require("../Assests/me.jpg").default} 
            alt="me"
            // style={{filter: 'drop-shadow(1px 2px 2px yellow) '}}
            className="col-span-4 md:col-span-1 transform  hover:scale-100 md:hover:scale-110 m-auto animate__zoomIn animate__delay-5s  w-2/12 md:w-full  md:h-full  md:hover:rotate-6 filter hue-rotate-30 contrast-125 shadow-2xl rounded-full md:rounded-br-lg md:rounded-none  md:ring-0 md:ring-offset-0 ring-2 ring-offset-4 ring-blue-400" />
        </div>
    )
}

export default AboutHome
