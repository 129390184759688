import React from 'react'
import HeaderIcon from './HeaderIcon'
import { AcademicCapIcon, BriefcaseIcon, HomeIcon } from '@heroicons/react/solid'
import { MailIcon, DesktopComputerIcon } from '@heroicons/react/outline'

function MobileMenu() {
    return (
        <div className="flex flex-col right-0 bg-gradient-to-r from-green-300 via-gray-100 to-pink-300 mt-12 justify-center animate__animated 
         bg-green-200 items-center absolute w-screen h-screen space-y-5  border-2 focus-within:shadow-inner  animate__bounceInDown ">

            <HeaderIcon src="/" title="𝓗𝓸𝓶𝓮" Icon={HomeIcon}/>
            <HeaderIcon src="/about" title="𝓐𝓫𝓸𝓾𝓽" Icon={AcademicCapIcon} />
            <HeaderIcon src="/skill" title="𝓢𝓴𝓲𝓵𝓵𝓼" Icon={BriefcaseIcon} />
            <HeaderIcon src="/project" title="𝓦𝓸𝓻𝓴" Icon={DesktopComputerIcon} />
            <HeaderIcon src="/contact" title="𝓒𝓸𝓷𝓽𝓪𝓬𝓽" Icon={MailIcon} />
        </div>
    )
}

export default MobileMenu
